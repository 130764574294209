import clsx from 'clsx'

export default function Logo({ className = undefined }: { className?: string }) {
  return (
    <svg
      width="510"
      height="840"
      viewBox="0 0 510 840"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('pointer-events-none', className)}
    >
      <g style={{ mixBlendMode: 'darken' }}>
        <path
          d="M193.008 313.95C227.634 348.6 283.773 348.6 318.398 313.95L480.768 151.464C515.394 116.814 515.394 60.6355 480.768 25.9855C446.143 -8.66445 390.004 -8.66445 355.378 25.9855L193.008 188.471C158.383 223.121 158.383 279.3 193.008 313.95Z"
          fill="#DE362F"
        />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path
          d="M477.815 363.246C443.189 328.596 387.05 328.596 352.425 363.246L193 522.783C158.375 557.433 158.375 613.612 193 648.262C227.626 682.912 283.765 682.912 318.39 648.262L477.815 488.724C512.44 454.074 512.44 397.896 477.815 363.246Z"
          fill="#DE362F"
        />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path
          d="M484.033 688.536C449.407 653.886 393.268 653.886 358.643 688.536C324.017 723.186 324.017 779.364 358.643 814.014C393.268 848.664 449.407 848.664 484.033 814.014C518.658 779.364 518.658 723.186 484.033 688.536Z"
          fill="#DE362F"
        />
      </g>
      <g style={{ mixBlendMode: 'darken' }}>
        <path
          d="M151.357 355.623C116.732 320.973 60.5925 320.973 25.9671 355.623C-8.65831 390.273 -8.65831 446.452 25.9671 481.102C60.5925 515.752 116.732 515.752 151.357 481.102C185.982 446.452 185.982 390.273 151.357 355.623Z"
          fill="#DE362F"
        />
      </g>
    </svg>
  )
}
