export default function Logo({ className }: { className?: string }) {
  return (
    <svg width="102" height="48" viewBox="0 0 102 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M40.537 37.6249C40.5645 37.6249 40.9427 37.5701 41.3044 37.7372V35.8792C40.8166 35.586 40.2164 35.5997 39.8245 35.5997C38.9036 35.5997 37.1166 36.0464 37.1166 38.4908V38.5758H36.1245V40.4749H37.1166V45.8378H39.5312V40.4749H42.0033V45.8378H44.4179V38.5758H39.5312C39.5449 38.0167 39.8519 37.6276 40.537 37.6276V37.6249Z"
        fill="currentColor"
      />
      <path d="M44.4175 35.9231H42.0029V38.0031H44.4175V35.9231Z" fill="currentColor" />
      <path
        d="M50.8998 38.436C49.935 38.436 49.2526 38.8416 48.8333 39.4281V38.5758H46.4187V45.8378H48.8333V41.9849C48.8333 41.2312 49.1813 40.5735 49.9926 40.5735C50.8038 40.5735 51.1793 41.2312 51.1793 41.9849V45.8378H53.5939V41.5656C53.5939 39.3458 52.366 38.4388 50.8998 38.4388V38.436Z"
        fill="currentColor"
      />
      <path
        d="M60.733 39.258H60.6919C60.6371 39.1319 59.9519 38.4331 58.6802 38.4331C57.0604 38.4331 55.2461 39.6608 55.2461 42.2039C55.2461 44.747 57.0604 45.9747 58.6802 45.9747C59.9519 45.9747 60.6343 45.2759 60.6919 45.1498H60.733V45.8349H63.1475V38.5729H60.733V39.258ZM59.1982 43.8372C58.3595 43.8372 57.6195 43.2233 57.6195 42.2039C57.6195 41.1845 58.3595 40.5706 59.1982 40.5706C60.0368 40.5706 60.7905 41.1708 60.7905 42.2039C60.7905 43.237 60.0642 43.8372 59.1982 43.8372Z"
        fill="currentColor"
      />
      <path
        d="M69.6295 38.436C68.6648 38.436 67.9823 38.8416 67.563 39.4281V38.5758H65.1484V45.8378H67.563V41.9849C67.563 41.2312 67.9111 40.5735 68.7223 40.5735C69.5336 40.5735 69.909 41.2312 69.909 41.9849V45.8378H72.3236V41.5656C72.3236 39.3458 71.0958 38.4388 69.6295 38.4388V38.436Z"
        fill="currentColor"
      />
      <path
        d="M77.8682 40.601C78.4958 40.601 78.9425 40.9627 79.2659 41.3683L81.0118 40.2228C80.2718 39.0225 79.1536 38.436 77.8709 38.436C75.9168 38.436 73.9736 39.735 73.9736 42.1931C73.9736 44.6513 75.9141 45.9776 77.8709 45.9776C79.1563 45.9776 80.2718 45.3638 81.0118 44.1909L79.2659 43.0454C78.9453 43.451 78.4985 43.8127 77.8682 43.8127C77.0295 43.8127 76.3471 43.1714 76.3471 42.1931C76.3471 41.2148 77.0323 40.601 77.8682 40.601Z"
        fill="currentColor"
      />
      <path
        d="M85.605 38.436C83.7194 38.436 81.8777 39.7624 81.8777 42.2205C81.8777 44.6787 83.692 45.9776 85.7311 45.9776C87.0987 45.9776 88.1183 45.4186 88.7048 44.6787L87.6441 43.5469C87.1124 43.9086 86.5835 44.021 85.997 44.021C85.1583 44.021 84.5307 43.7277 84.2374 43.1413H89.168C89.6695 39.7076 87.4495 38.436 85.6078 38.436H85.605ZM84.1525 41.4368C84.4046 40.7517 85.0185 40.4447 85.6325 40.4447C86.2464 40.4447 86.874 40.7517 87.0439 41.4368H84.1525Z"
        fill="currentColor"
      />
      <path
        d="M102 7.41498V0.843506H85.8765H82.0532H58.0255H57.1512H36.1245V29.8259H43.3106V18.3546H52.5358V12.1915H43.3106V7.41498H57.1512H58.0255H63.6001V29.8259H70.7862V7.41498H82.0532V29.8259H89.2393V18.3546H98.4645V12.1915H89.2393V7.41498H102Z"
        fill="currentColor"
      />
      <path
        d="M10.825 17.5954C12.7682 19.5383 15.9173 19.5383 17.8577 17.5954L26.965 8.48906C28.9082 6.54612 28.9082 3.39741 26.965 1.45721C25.0219 -0.485735 21.8728 -0.485735 19.9324 1.45721L10.825 10.5635C8.88189 12.5065 8.88189 15.6552 10.825 17.5954Z"
        fill="#DE372F"
      />
      <path
        d="M26.8006 20.3574C24.8574 18.4144 21.7084 18.4144 19.768 20.3574L10.825 29.2993C8.88189 31.2422 8.88189 34.3909 10.825 36.3311C12.7682 38.2741 15.9173 38.2741 17.8577 36.3311L26.8006 27.3892C28.7438 25.4463 28.7438 22.2976 26.8006 20.3574Z"
        fill="#DE372F"
      />
      <path
        d="M27.1487 38.5895C25.2055 36.6466 22.0565 36.6466 20.1161 38.5895C18.1729 40.5325 18.1729 43.6812 20.1161 45.6214C22.0592 47.5643 25.2083 47.5643 27.1487 45.6214C29.0919 43.6784 29.0919 40.5297 27.1487 38.5895Z"
        fill="#DE372F"
      />
      <path
        d="M8.49001 19.9301C6.54685 17.9872 3.39779 17.9872 1.45737 19.9301C-0.48579 21.873 -0.48579 25.0218 1.45737 26.962C3.40053 28.9049 6.54959 28.9049 8.49001 26.962C10.4332 25.019 10.4332 21.8703 8.49001 19.9301Z"
        fill="#DE372F"
      />
    </svg>
  )
}
